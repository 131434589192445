import { toast } from "react-toastify";
import * as actions from "../../constants/actionTypes";
import i18next from "i18next";
import * as ApiError from "@shared/apiError";

export const getErrorMessageFromApiError = (apiError: ApiError.ApiError) => {
  // Admin errors require no translations
  const apiErrorToMessage: { [K in ApiError.ApiError]: string } = {
    "admin.patchGroup.integrationUsernameInUse":
      "Integration username is already in use by another group",
    "admin.postGroup.integrationUsernameInUse":
      "Integration username is already in use by another group",
    "admin.deleteGroup.smart":
      "An error occurred while deleting group from old SMART.",
    "admin.deleteGroup.scaleTags":
      "The group or some of its child groups has scale tags. Delete or move them to another group before deleting this group.",
    "admin.devices.task.create.invalidSerial":
      "Could not create task because of non-existent serial number",
    "admin.devices.errorTask.create.duplicateName":
      "This error task name is already in use, please use another name.",
    "admin.devices.errorTask.update.duplicateName":
      "This error task name is already in use, please use another name.",
    "admin.putGroupLicenses.smart":
      "An error occurred while updating group licenses to old SMART.",
    "admin.postUser.email":
      "Succesfully created user but could not send registration email.",
    "admin.postUser.nameExists": "User with email already exists.",
    "password.verify.notRegistered": i18next.t(
      "notify.password.notRegistered",
      "Cannot reset the password for an account that has not yet been registered."
    ),
    "scale.createUserGroup.duplicateName": i18next.t(
      "notify.scaleUserGroupCreate.duplicateName",
      "Cannot create user group because the name is in use by another user group"
    ),
    "scale.createUserGroup.duplicateCustomerId": i18next.t(
      "notify.scaleUserGroupCreate.duplicateCustomerId",
      "Cannot create user group because the customer id is in use by another user group"
    ),
    "scale.updateUserGroup.duplicateName": i18next.t(
      "notify.scaleUserGroupUpdate.duplicateName",
      "Cannot update user group because the name is in use by another user group"
    ),
    "scale.updateUserGroup.duplicateCustomerId": i18next.t(
      "notify.scaleUserGroupUpdate.duplicateCustomerId",
      "Cannot update user group because the customer id is in use by another user group"
    ),
    "user.serviceTool.login.expired": i18next.t(
      "notify.serviceToolLogin.expired",
      "This login attempt has timed out. Please start a new login from service tool."
    ),
    "user.serviceTool.login.notAllowed": i18next.t(
      "notify.serviceToolLogin.notAllowed",
      "You are not allowed to login to service tool. Please contact Europress."
    ),
    "user.serviceTool.login.notFound": i18next.t(
      "notify.serviceToolLogin.notFound",
      "Unable to login with the provided user code. Please check that the code is correctly copied from service tool."
    ),
    "user.login.lockout": i18next.t(
      "notify.userLockedOut",
      "Too many failed logins, please wait a moment a try again"
    ),
    "user.login.invalidOTP": i18next.t(
      "notify.userInvalidOTP",
      "The login code is incorrect"
    ),
  };

  return apiErrorToMessage[apiError];
};

type NotifyAction =
  | "FETCH_TOKEN_FAILURE"
  | "FETCH_USER_FOR_REGISTRATION_FAILURE"
  | "SEND_REGISTER_LINK_TO_USER_SUCCESS"
  | "SEND_REGISTER_LINK_TO_USER_FAILURE"
  | "RESET_PASSWORD_SUCCESS"
  | "RESET_PASSWORD_FAILURE"
  | "FORGOT_PASSWORD_SUCCESS"
  | "VERIFY_RESET_PASSWORD_TOKEN_FAILURE"
  | typeof actions.UPDATE_USER_FOR_ADMIN_SUCCESS
  | "UPDATE_USER_FOR_ADMIN_FAILURE_NAME_EXISTS"
  | "UPDATE_USER_FOR_ADMIN_FAILURE"
  | "CREATE_USER_FOR_ADMIN_SUCCESS"
  | "CREATE_USER_FOR_ADMIN_FAILURE"
  | "REGISTER_USER_SUCCESS"
  | "REGISTER_USER_FAILURE"
  | "DELETE_USER_FOR_ADMIN_SUCCESS"
  | "DELETE_USER_FOR_ADMIN_FAILURE"
  | "DISABLE_USER_FOR_ADMIN_SUCCESS"
  | "DISABLE_USER_FOR_ADMIN_FAILURE"
  | "PATCH_DEVICE_DETAIL_SUCCESS"
  | "PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS"
  | "PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS"
  | "PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS"
  | "PATCH_DEVICE_FILL_LEVEL_MODE"
  | "PATCH_HG_DEVICE_SUCCESS"
  | "RESET_BALE_COUNTER_SUCCESS"
  | "DELETE_GROUP_FOR_ADMIN_SUCCESS"
  | typeof actions.GET_ALL_HG_DEVICES_FAILURE
  | typeof actions.PATCH_HG_DEVICE_FAILURE
  | typeof actions.GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE
  | typeof actions.PATCH_RETROFIT_LIGHT_DEVICE_FAILURE
  | typeof actions.GET_ALL_LICENSES_FAILURE
  | typeof actions.GET_GROUP_LICENSES_FAILURE
  | typeof actions.UPDATE_GROUP_LICENSES_SUCCESS
  | typeof actions.UPDATE_GROUP_LICENSES_FAILURE
  | "DELETE_GROUP_FOR_ADMIN_FAILURE"
  | "UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS"
  | "UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE"
  | "FETCH_SCALE_EVENTS_FAILURE"
  | "UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS"
  | "UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE"
  | typeof actions.GET_ALL_DEVICE_TASKS_FAILURE
  | typeof actions.CREATE_DEVICE_TASKS_SUCCESS
  | typeof actions.CREATE_DEVICE_TASKS_FAILURE
  | typeof actions.UPDATE_DEVICE_TASK_SUCCESS
  | typeof actions.UPDATE_DEVICE_TASK_FAILURE
  | typeof actions.DELETE_DEVICE_TASK_SUCCESS
  | typeof actions.DELETE_DEVICE_TASK_FAILURE
  | typeof actions.UPDATE_SCALE_TAG_FAILURE
  | "DELETE_SCALE_USER_GROUP_FAILURE"
  | "CREATE_SCALE_USER_GROUP_FAILURE"
  | "UPDATE_SCALE_USER_GROUP_FAILURE"
  | "FETCH_SCALE_RESULTS_FAILURE"
  | "FETCH_SCALE_REPORT_MONTHS_FAILURE"
  | "FETCH_SCALE_REPORTS_FAILURE"
  | "FETCH_SCALE_REPORT_SETTINGS_FAILURE"
  | "FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE"
  | "GENERATE_SCALE_REPORT_FAILURE"
  | "ADMIN_IMPORT_SCALE_TAGS_SUCCESS"
  | "ADMIN_IMPORT_SCALE_TAGS_FAILURE"
  | "UPDATE_SCALE_REPORT_SETTINGS_SUCCESS"
  | "UPDATE_SCALE_REPORT_SETTINGS_FAILURE"
  | "UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS"
  | "UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE"
  | "FETCH_SCALE_FIRMWARES_FAILURE"
  | "FETCH_SCALES_FAILURE"
  | "FETCH_DASHBOARD_FAILURE"
  | "POST_DASHBOARD_WORK_LOG_UPDATE_FAILURE"
  | "POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS"
  | typeof actions.ADMIN_UPDATE_SCALE_SUCCESS
  | typeof actions.ADMIN_UPDATE_SCALE_FAILURE
  | "POST_SCALE_UPLOAD_FILE_FAILURE"
  | "POST_SCALE_UPLOAD_FILE_SUCCESS"
  | "RESEND_SCALE_REPORT_SUCCESS"
  | "RESEND_SCALE_REPORT_FAILURE"
  | "ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE"
  | "DEFAULT_ERROR"
  | "ENABLE_USER_MFA_FOR_ADMIN_SUCCESS"
  | "ENABLE_USER_MFA_FOR_ADMIN_FAILURE"
  | "DISABLE_USER_MFA_FOR_ADMIN_SUCCESS"
  | "DISABLE_USER_MFA_FOR_ADMIN_FAILURE"
  | "VERIFY_MFA_ACTION_FAILURE"
  | "ENABLE_MFA_FOR_USER_SUCCESS"
  | "ENABLE_MFA_FOR_USER_FAILURE"
  | "DISABLE_MFA_FOR_USER_SUCCESS"
  | "DISABLE_MFA_FOR_USER_FAILURE"
  | "FETCH_ANOMALIES_FAILURE"
  | "UPDATE_ANOMALIES_SUCCESS"
  | "UPDATE_ANOMALIES_FAILURE"
  | "DEFAULT_ERROR";
const getMessageFromAction = (action: NotifyAction) => {
  const actionToMessage: { [K in NotifyAction]: string } = {
    [actions.FETCH_TOKEN_FAILURE]: i18next.t(
      "notify.loginFailed",
      "The email or password is incorrect"
    ),
    [actions.FETCH_USER_FOR_REGISTRATION_FAILURE]: i18next.t(
      "notify.registerLinkInvalid",
      "The register link is invalid"
    ),
    [actions.SEND_REGISTER_LINK_TO_USER_SUCCESS]: i18next.t(
      "notify.registerLinkSent",
      "Register link sent to user"
    ),
    [actions.SEND_REGISTER_LINK_TO_USER_FAILURE]: i18next.t(
      "notify.sendRegisterLinkFailed",
      "Sending register link to user failed"
    ),
    [actions.RESET_PASSWORD_SUCCESS]: i18next.t(
      "notify.passwordReseted",
      "Password reseted"
    ),
    [actions.RESET_PASSWORD_FAILURE]: i18next.t(
      "notify.resetPasswordFailed",
      "Resetting password failed"
    ),
    [actions.FORGOT_PASSWORD_SUCCESS]: i18next.t(
      "notify.resetPasswordMailSent",
      "Reset password email has been sent."
    ),
    [actions.VERIFY_RESET_PASSWORD_TOKEN_FAILURE]: i18next.t(
      "notify.invalidResetPasswordLink",
      "The reset password link is invalid"
    ),
    [actions.UPDATE_USER_FOR_ADMIN_SUCCESS]: "Succesfully updated user",
    [actions.UPDATE_USER_FOR_ADMIN_FAILURE_NAME_EXISTS]: i18next.t(
      "notify.userExistsFailure",
      "User with email exists already"
    ),
    [actions.UPDATE_USER_FOR_ADMIN_FAILURE]: i18next.t(
      "notify.userUpdateFailed",
      "Updating user failed"
    ),
    [actions.CREATE_USER_FOR_ADMIN_SUCCESS]: i18next.t(
      "notify.userCreateSuccess",
      "The user was created."
    ),
    [actions.CREATE_USER_FOR_ADMIN_FAILURE]: i18next.t(
      "notify.userCreateFailed",
      "Creating user failed"
    ),
    [actions.REGISTER_USER_SUCCESS]: i18next.t(
      "notify.userRegisterSuccess",
      "Registration succesful. Please login with your new credentials."
    ),
    [actions.REGISTER_USER_FAILURE]: i18next.t(
      "notify.userRegisterFailed",
      "Registration failed"
    ),
    [actions.DELETE_USER_FOR_ADMIN_SUCCESS]: i18next.t(
      "notify.userDeleteSuccess",
      "The user was deleted"
    ),
    [actions.DELETE_USER_FOR_ADMIN_FAILURE]: i18next.t(
      "notify.userDeleteFailed",
      "Deleting user failed"
    ),
    [actions.DISABLE_USER_FOR_ADMIN_SUCCESS]: i18next.t(
      "notify.userDisableSuccess",
      "The user was deleted"
    ),
    [actions.DISABLE_USER_FOR_ADMIN_FAILURE]: i18next.t(
      "notify.userDisableFailed",
      "Deactivating user failed"
    ),
    [actions.PATCH_DEVICE_DETAIL_SUCCESS]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    [actions.PATCH_HG_DEVICE_SUCCESS]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    [actions.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    [actions.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    [actions.PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    [actions.PATCH_DEVICE_FILL_LEVEL_MODE]: i18next.t(
      "notify.deviceDetailsSaved",
      "Device details saved"
    ),
    RESET_BALE_COUNTER_SUCCESS: i18next.t(
      "notify.baleCounterReset",
      "Bale counter reset"
    ),
    [actions.DELETE_GROUP_FOR_ADMIN_SUCCESS]: "Group deleted",
    [actions.DELETE_GROUP_FOR_ADMIN_FAILURE]: "Deleting group failed",
    [actions.GET_ALL_LICENSES_FAILURE]: "Unable to get licenses",
    [actions.GET_ALL_HG_DEVICES_FAILURE]: "Unable to get H&G Devices",
    [actions.PATCH_HG_DEVICE_FAILURE]: "Unable to update H&G Device",
    [actions.GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE]:
      "Unable to get Retrofit Light Devices",
    [actions.PATCH_RETROFIT_LIGHT_DEVICE_FAILURE]:
      "Unable to update Retrogit Light Device",
    [actions.GET_GROUP_LICENSES_FAILURE]: "Unable to get group licenses",
    [actions.UPDATE_GROUP_LICENSES_SUCCESS]: "Succesfully updated licenses",
    [actions.UPDATE_GROUP_LICENSES_FAILURE]: "Unable to update group licenses",
    [actions.UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS]:
      "Updated owner group for devices",
    [actions.UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE]:
      "Updating owner group for devices failed",
    [actions.GET_ALL_DEVICE_TASKS_FAILURE]: "Could not fetch device tasks",
    [actions.CREATE_DEVICE_TASKS_SUCCESS]: "Tasks created",
    [actions.CREATE_DEVICE_TASKS_FAILURE]: "Creating tasks failed",
    [actions.UPDATE_DEVICE_TASK_SUCCESS]: "Task updated",
    [actions.UPDATE_DEVICE_TASK_FAILURE]: "Updating task failed",
    [actions.DELETE_DEVICE_TASK_SUCCESS]: "Task deleted",
    [actions.DELETE_DEVICE_TASK_FAILURE]: "Could not delete task",
    [actions.FETCH_SCALE_EVENTS_FAILURE]: i18next.t(
      "notify.failedGetScaleMeasurements",
      "Failed to get scale measurements"
    ),
    [actions.UPDATE_SCALE_TAG_FAILURE]: i18next.t(
      "notify.failedUpdateScaleTag",
      "Failed to update scale key alias"
    ),
    [actions.UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS]:
      "Updated operator group for devices",
    [actions.UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE]:
      "Updating operator group for devices failed",
    [actions.DELETE_SCALE_USER_GROUP_FAILURE]: "Deleting group failed",
    [actions.CREATE_SCALE_USER_GROUP_FAILURE]: "Creating group failed",
    [actions.UPDATE_SCALE_USER_GROUP_FAILURE]: "Updating group failed",
    [actions.FETCH_SCALE_RESULTS_FAILURE]: "Failed to get scale information",
    [actions.FETCH_SCALE_REPORT_MONTHS_FAILURE]: "Failed to get list of months",
    [actions.FETCH_SCALE_REPORTS_FAILURE]: "Failed to fetch scale reports",
    [actions.FETCH_SCALE_REPORT_SETTINGS_FAILURE]:
      "Failed to get report settings",
    [actions.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE]:
      "Failed to get report settings",
    [actions.GENERATE_SCALE_REPORT_FAILURE]: "Failed to create the report",
    [actions.ADMIN_IMPORT_SCALE_TAGS_SUCCESS]: "Keys imported",
    [actions.ADMIN_IMPORT_SCALE_TAGS_FAILURE]: "Failed to import keys",
    [actions.UPDATE_SCALE_REPORT_SETTINGS_SUCCESS]: "Settings updated",
    [actions.UPDATE_SCALE_REPORT_SETTINGS_FAILURE]: "Failed to update settings",
    [actions.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS]:
      "Emails updated",
    [actions.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE]:
      "Failed to update emails",
    [actions.FETCH_SCALE_FIRMWARES_FAILURE]: "Failed to fetch scale firmwares",
    [actions.FETCH_SCALES_FAILURE]: "Failed to fetch scales",
    [actions.FETCH_DASHBOARD_FAILURE]: "Failed to fetch dashboard",
    [actions.ADMIN_UPDATE_SCALE_SUCCESS]: "Succesfully updated scale",
    [actions.ADMIN_UPDATE_SCALE_FAILURE]: "Unable to update scale",
    [actions.POST_DASHBOARD_WORK_LOG_UPDATE_FAILURE]:
      "Failed to update in the works",
    [actions.POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS]:
      "In the works marking updated succesfully",
    [actions.POST_SCALE_UPLOAD_FILE_FAILURE]:
      "Failed to update scale firmware file",
    [actions.POST_SCALE_UPLOAD_FILE_SUCCESS]:
      "Scale upload file was set successfully to selected scales",
    [actions.RESEND_SCALE_REPORT_SUCCESS]: "The report has been resent",
    [actions.RESEND_SCALE_REPORT_FAILURE]: "Failed to resend the report",
    [actions.ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE]:
      "Failed to add the key to a group.",
    [actions.ENABLE_USER_MFA_FOR_ADMIN_SUCCESS]: "Enabled MFA for user",
    [actions.ENABLE_USER_MFA_FOR_ADMIN_FAILURE]:
      "Failed to enable MFA for user",
    [actions.DISABLE_USER_MFA_FOR_ADMIN_SUCCESS]: "Disabled MFA for user",
    [actions.DISABLE_USER_MFA_FOR_ADMIN_FAILURE]:
      "Failed to disable MFA for user",
    [actions.VERIFY_MFA_ACTION_FAILURE]: i18next.t(
      "mfaForm.notifications.sendFailure",
      "Failed to send e-mail"
    ),
    [actions.ENABLE_MFA_FOR_USER_SUCCESS]: i18next.t(
      "mfaForm.notifications.enableSuccess",
      "Failed to send e-mail"
    ),
    [actions.ENABLE_MFA_FOR_USER_FAILURE]: i18next.t(
      "mfaForm.notifications.enableFailure",
      "Failed to change MFA settings. Check your verification code and try again."
    ),
    [actions.DISABLE_MFA_FOR_USER_SUCCESS]: i18next.t(
      "mfaForm.notifications.disableSuccess",
      "MFA has been disabled."
    ),
    [actions.DISABLE_MFA_FOR_USER_FAILURE]: i18next.t(
      "mfaForm.notifications.disableFailure",
      "Failed to change MFA settings. Check your verification code and try again."
    ),
    [actions.FETCH_ANOMALIES_FAILURE]: "Failed to load anomalies",
    [actions.UPDATE_ANOMALIES_SUCCESS]: "Anomaly data updated",
    [actions.UPDATE_ANOMALIES_FAILURE]: "Failed to update anomaly data",
    DEFAULT_ERROR: "An error occurred",
  };

  return actionToMessage[action];
};

export const NotifySuccess = (action: NotifyAction) => {
  toast.success(getMessageFromAction(action));
};

export const NotifyError = (
  errorAction: NotifyAction | "DEFAULT_ERROR" = "DEFAULT_ERROR",
  apiError?: ApiError.ApiError
) => {
  // Use error code to get error message if it exists, default to error action
  const message =
    apiError !== undefined
      ? getErrorMessageFromApiError(apiError)
      : getMessageFromAction(errorAction);

  toast.error(message, { autoClose: false, closeOnClick: false });
};
