import { useCallback, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { formatISO9075, parseISO } from "date-fns";
import { Prompt } from "react-router-dom";

import * as TYPES from "../../../constants/actionTypes";
import { Table } from "../../../components/Common/Table";
import AnomalyChart from "./AnomalyChart";
import { PressureAnomaly } from "../../../interfaces/types";
import {
  PrimaryButtonLarge,
  ConfirmButtonLarge,
  CancelButtonLarge,
} from "../../../components/Common/Button";

// TODO: Move this somewhere else?
interface AnomalyFlag {
  id: number;
  value: boolean | null;
  title: string;
}
const AnomalyFlags: AnomalyFlag[] = [
  {
    id: -1,
    value: null,
    title: "Unlabeled",
  },
  {
    id: 0,
    value: false,
    title: "No",
  },
  {
    id: 1,
    value: true,
    title: "Yes",
  },
];

const AnomaliesPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnomaliesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const AnomaliesTableContainer = styled.div`
  > * {
    margin: 1rem;
  }
`;

const AnomaliesChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;

const AnomalyFlagSelect = styled.select``;

const ModifiedCheckmark = styled.span`
  font-size: 1.3rem;
  margin-right: 8px;
  color: green;
`;

const ConfirmActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ConfirmActionButtons = styled.div`
  display: flex;

  > button {
    margin-left: 10px;
  }
`;
const AnomalyTableStyles = css`
  .resizer {
    right: 0;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    touch-action: none;
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAnomalies: (token: string, payload: any) => {
      dispatch({
        type: TYPES.UPDATE_ANOMALIES,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    fetchAnomalies: (token: string) => {
      dispatch({
        type: TYPES.FETCH_ANOMALIES,
        payload: {
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    anomalies: state.admin.anomalies,
    isLoaded: state.admin.isLoaded,
    token: state.token.key,
  };
};

interface PressureAnomaliesProps {
  anomalies: PressureAnomaly[];
  updateAnomalies(token: string, data: any): void;
  fetchAnomalies(token: string): void;
  isLoaded: boolean;
  token: any;
}
const PressureAnomaliesConnected = (props: PressureAnomaliesProps) => {
  const token = useSelector((state: any) => state.token.key);
  // TODO: I don't quite like having the displayed anomalies live in State, but this'll do for now

  // Anomalies to display in the table
  const [displayedAnomalies, setDisplayedAnomalies] = useState<
    PressureAnomaly[]
  >([]);

  // Anomalies to display on the Chart
  const [selectedAnomalies, setSelectedAnomalies] = useState<PressureAnomaly[]>(
    []
  );

  const [modifiedAnomalies, setModifiedAnomalies] = useState<
    { id: number; isAnomaly: boolean | null }[]
  >([]);

  const [confirmMode, setConfirmMode] = useState<boolean>(false);

  const { fetchAnomalies, updateAnomalies, anomalies } = props;

  const updateSelectedAnomalies = useCallback(
    (indexes: number[]) => {
      setSelectedAnomalies(indexes.map((i) => anomalies[i]));
    },
    [anomalies]
  );

  const isModified = (id: number) => {
    const isModified = modifiedAnomalies.find((a) => a.id === id);
    return !!isModified;
  };

  const handleSetAnomalyFlag = (id: number, flag: number) => {
    setModifiedAnomalies([
      ...modifiedAnomalies.filter((a) => a.id !== id),
      { id: id, isAnomaly: AnomalyFlags.find((f) => f.id === flag)!.value },
    ]);
  };

  /*
   * TODO: AnomalyTypes are not yet implemented
  const handleSetAnomalyType = (anomaly: PressureAnomaly, data: any) => {
    if (anomaly.anomaly_type === data.anomaly_type) {
      // remove the key if the new value is the same as the initial value (= the object has not been modified)
      const { [anomaly.id]: deleted, ...rest } = modifiedAnomalies;
      setModifiedAnomalies(rest);
    } else {
      setModifiedAnomalies((prev) => ({
        ...prev,
        [anomaly.id]: { ...data },
      }));
    }
  };
  */

  const getColumns = useMemo(
    () => [
      {
        Header: "Anomaly ID",
        id: "id",
        accessor: "id",
        minWidth: 60,
      },
      {
        Header: "Pressure Curve ID",
        id: "pressureDataId",
        accessor: "pressureDataId",
        minWidth: 60,
      },
      {
        Header: "Device serial number",
        id: "deviceSerialNumber",
        accessor: "deviceSerialNumber",
        minWidth: 60,
      },
      {
        Header: "Created at",
        accessor: ({ createdAt }: any) => formatISO9075(parseISO(createdAt)),
        disableFilters: true,
        minWidth: 80,
      },
      {
        Header: "Waste fraction",
        id: "wasteFraction",
        accessor: "wasteFraction",
        minWidth: 80,
      },
      {
        Header: "Device owner",
        id: "deviceOwner",
        accessor: "deviceOwner",
        minWidth: 60,
      },
      {
        // TODO: Anomaly types will be implemented later
        Header: "Anomaly type",
        accessor: "anomalyType",
        disableFilters: true,
        Cell: ({ row }: any) => {
          return `Anomaly Type ${row.values.anomalyType}`;
        },
        minWidth: 60,
        /* 
        Cell: ({ row }: any) => {
          if (confirmMode) {
            if (isModified(row.original.id)) {
              return <span>{modifiedAnomalies[row.original.id].anomaly_type}</span>;
            }
            return null;
          } else {
            return (
              <>
                <select
                  name="anomalyType"
                  id="anomalyType"
                  defaultValue={
                    // Use the modified value if it is set, otherwise default to the initial value.
                    modifiedAnomalies[row.values.id]?.anomaly_type ||
                    row.values.anomaly_type
                  }
                  onChange={(e) =>
                    handleSetAnomalyType(row.values, {
                      anomaly_type: parseInt(e.target.value),
                    })
                  }
                >
                  <option disabled={true} value={row.values.anomaly_type}>
                    Anomaly
                  </option>
                </select>
              </>
            );
          }
        }, 
        */
      },
      {
        Header: "Score",
        accessor: "anomalyScore",
        disableFilters: true,
        Cell: (row: any) => <strong>{row.value.toFixed(3)}</strong>,
        minWidth: 40,
      },
      {
        // is_anomaly is saved to the DB as true, false, or null, which makes typing a little wonky.
        Header: "Is anomaly",
        accessor: (row: any) =>
          isModified(row.id)
            ? modifiedAnomalies.find((a) => a.id === row.id)!.isAnomaly
            : "isAnomaly",
        disableFilters: true,
        Cell: ({ row }: any) => {
          if (confirmMode) {
            if (isModified(row.original.id)) {
              console.log(row.original.isAnomaly);
              console.log(AnomalyFlags);
              return (
                <strong>
                  {
                    AnomalyFlags.find(
                      (f) => f.value === row.original.isAnomaly
                    )!.title
                  }
                </strong>
              );
            } else {
              return null;
            }
          } else {
            const defaultSelection = isModified(row.original.id)
              ? modifiedAnomalies.find((a) => a.id === row.original.id)!
                  .isAnomaly
              : row.original.isAnomaly;
            return (
              <AnomalyFlagSelect
                name="isAnomaly"
                id="isAnomaly"
                defaultValue={
                  AnomalyFlags.find((f) => f.value === defaultSelection)?.id
                }
                onChange={(e) =>
                  handleSetAnomalyFlag(
                    row.original.id,
                    parseInt(e.target.value)
                  )
                }
              >
                {AnomalyFlags.map((f: AnomalyFlag) => (
                  <option disabled={f.id === -1} value={f.id}>
                    {f.title}
                  </option>
                ))}
              </AnomalyFlagSelect>
            );
          }
        },
      },
      {
        Header: "",
        id: "modified",
        Cell: ({ row }: any) => {
          return (
            <span>
              {isModified(row.original.id) && (
                <ModifiedCheckmark>✓</ModifiedCheckmark>
              )}
            </span>
          );
        },
        width: 40,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modifiedAnomalies, confirmMode]
  );

  useEffect(() => {
    fetchAnomalies(token);
  }, [fetchAnomalies, token]);

  useEffect(() => {
    if (confirmMode) {
      setDisplayedAnomalies(
        modifiedAnomalies.map((m) => ({
          ...anomalies.find((a) => a.id === m.id)!,
          isAnomaly: m.isAnomaly,
        }))
      ); // ))))) oh no
    } else {
      setDisplayedAnomalies(anomalies);
    }
  }, [anomalies, confirmMode, modifiedAnomalies]);

  // TODO: Autoselect for graph when in confirm mode?
  const handleConfirmMode = (newState: boolean) => {
    setConfirmMode(newState);
    setSelectedAnomalies([]);
  };

  const handleUpdateAnomalies = () => {
    updateAnomalies(token, modifiedAnomalies);
    setModifiedAnomalies([]); // TODO: This will reset the modifiedAnomalies state even if the update fails, perhaps add a "success" variable to the store?
  };

  if (!props.isLoaded) {
    return <div>Loading...</div>;
  }

  if (anomalies?.length === 0) {
    return <div>No data.</div>;
  }

  return (
    <>
      <AnomaliesPage>
        <Prompt
          when={modifiedAnomalies.length > 0}
          message="There are unsaved anomalies, do you want to discard the changes?"
        />
        <>
          <AnomaliesHeader>
            {confirmMode ? (
              <ConfirmActions>
                <div>
                  The following <b>{modifiedAnomalies.length}</b> anomalies will
                  be rated. Click CONFIRM to save these changes to the database.{" "}
                  <br />
                </div>
                <ConfirmActionButtons>
                  <CancelButtonLarge onClick={() => handleConfirmMode(false)}>
                    Cancel
                  </CancelButtonLarge>
                  <ConfirmButtonLarge
                    onClick={() => {
                      handleUpdateAnomalies();
                      handleConfirmMode(false); // Return to original view, but do not reset the state
                    }}
                  >
                    Confirm
                  </ConfirmButtonLarge>
                </ConfirmActionButtons>
              </ConfirmActions>
            ) : (
              <>
                <div>
                  <b>{Object.keys(modifiedAnomalies).length}</b> of{" "}
                  <b>{anomalies?.length}</b> anomalies rated
                </div>
                <PrimaryButtonLarge
                  onClick={() => handleConfirmMode(true)}
                  disabled={Object.keys(modifiedAnomalies).length === 0}
                >
                  Save
                </PrimaryButtonLarge>
              </>
            )}
          </AnomaliesHeader>
          <AnomaliesTableContainer>
            <Table
              data={displayedAnomalies}
              columns={getColumns}
              enableFilters={true}
              paginate={true}
              pageSize={10}
              onSelectionChange={updateSelectedAnomalies}
              selectableCondition={(row: any) =>
                row.original.model !== "INVALID"
              }
              resizeable={true}
              initialState={{
                sortBy: [{ id: "anomalyScore", desc: true }],
                // TODO: Autoselect the index of the anomaly with the highest anomaly_score.
                //
                // [0] won't work, because the table's indexes are not sorted by score, but by sorting order.
                // Maybe they could be sorted by score from the API end, and then we could simply select 0?
                //
                // Alternatively, the best (but by far most time consuming option) would be to deconstruct
                // the entire table component and modify everything through the useTable hook.
                //
                // selectedRowIds: [0]
              }}
              sortable={true}
              tableStyles={AnomalyTableStyles}
            />
          </AnomaliesTableContainer>
          <AnomaliesChartContainer>
            <AnomalyChart anomalies={selectedAnomalies} />
          </AnomaliesChartContainer>
        </>
      </AnomaliesPage>
    </>
  );
};

export const PressureAnomalies = connect(
  mapStateToProps,
  mapDispatchToProps
)(PressureAnomaliesConnected);
